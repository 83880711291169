import {
  IEducationalExperience,
  IWorkExperience
} from '@/models/person/interfaces/experiences'
import { Api } from './api'

const baseURL = process.env.ACADEMIC_API_ENDPOINT
export class PersonApi {
  static async findWorkExperiences(
    personId: string
  ): Promise<IWorkExperience[]> {
    return Api.get<IWorkExperience[]>(
      `${baseURL}/v1/person/${personId}/work-experiences`
    )
  }

  static async findEducationalExperiences(
    personId: string
  ): Promise<IEducationalExperience[]> {
    return Api.get<IEducationalExperience[]>(
      `${baseURL}/v1/person/${personId}/educational-experiences`
    )
  }
}
