import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "p-grid"
}
const _hoisted_2 = { class: "p-col-12 p-d-flex p-jc-end" }
const _hoisted_3 = { class: "p-col-8" }
const _hoisted_4 = { class: "p-pt-3" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "p-pt-3" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "p-col-4" }
const _hoisted_11 = { class: "p-pt-3" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Experience = _resolveComponent("Experience")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            disabled: !_ctx.person?.cvFileLink,
            onClick: _cache[0] || (_cache[0] = () => _ctx.window.open(_ctx.person?.cvFileLink || '', '_blank')),
            icon: "pi pi-download",
            class: "p-button-text",
            label: "Descargar CV",
            type: "button"
          }, null, 8, ["disabled"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Panel, {
            header: "Educación",
            class: "brain-panel p-mb-3"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.educationalExperiences.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.educationalExperiences, (exp, index) => {
                        return (_openBlock(), _createBlock(_component_Experience, {
                          exp: exp,
                          key: exp.id,
                          divider: index + 1 !== _ctx.educationalExperiences.length
                        }, null, 8, ["exp", "divider"]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, "No hay datos para mostrar"))
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Panel, {
            header: "Experiencia",
            class: "brain-panel"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.workExperiences.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workExperiences, (exp, index) => {
                        return (_openBlock(), _createBlock(_component_Experience, {
                          exp: exp,
                          key: exp.id,
                          divider: index + 1 !== _ctx.workExperiences.length
                        }, null, 8, ["exp", "divider"]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, "No hay datos para mostrar"))
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_Panel, {
            header: "Redes",
            class: "brain-panel"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                (_ctx.socialNetworks.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialNetworks, (net) => {
                        return (_openBlock(), _createElementBlock("div", { key: net }, [
                          _createTextVNode(_toDisplayString(_ctx.capitalize(net)) + ": ", 1),
                          _createElementVNode("a", {
                            href: _ctx.person.socialNetworks[net],
                            target: "_blank"
                          }, _toDisplayString(_ctx.person.socialNetworks[net]), 9, _hoisted_13)
                        ]))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_14, "El usuario no tiene redes sociales"))
              ])
            ]),
            _: 1
          })
        ])
      ]))
}