
import { defineComponent, PropType } from 'vue'
import { DateTime } from 'luxon'
import Divider from 'primevue/divider'
import {
  IEducationalExperience,
  IWorkExperience
} from '@/models/person/interfaces/experiences'
export default defineComponent({
  components: {
    Divider
  },
  props: {
    exp: {
      type: Object as PropType<IEducationalExperience | IWorkExperience>
    },
    divider: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {
      DateTime
    }
  }
})
