import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-eb119492")
const _hoisted_1 = { class: "p-d-flex p-jc-between" }
const _hoisted_2 = { class: "p-m-0" }
const _hoisted_3 = { key: 0 }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.exp.title), 1),
        _createElementVNode("small", null, _toDisplayString(_ctx.exp.institution || _ctx.exp.company), 1)
      ]),
      (_ctx.exp.range.from && (_ctx.exp.range.currently || _ctx.exp.range.to))
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Desde " + _toDisplayString(_ctx.DateTime.fromISO(_ctx.exp.range.from).toFormat('LLLL yyyy')) + " a " + _toDisplayString(_ctx.exp.range.currently
          ? 'la actualidad'
          : _ctx.DateTime.fromISO(_ctx.exp.range.to).toFormat('LLLL yyyy')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.exp.description), 1),
    (_ctx.divider)
      ? (_openBlock(), _createBlock(_component_Divider, {
          key: 0,
          class: "p-mt-5 p-mb-5"
        }))
      : _createCommentVNode("", true)
  ], 64))
}