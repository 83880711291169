
import { capitalize, computed, defineComponent, onMounted, ref } from 'vue'
import Panel from 'primevue/panel'
import Experience from '../components/Experience.vue'
import { PersonApi } from '@/api/person.api'
import { injectStrict } from '@/utils/injectStrict'
import { PersonKey } from '../keys'
import {
  IEducationalExperience,
  IWorkExperience
} from '@/models/person/interfaces/experiences'
import { SocialNetworks } from '@/models/person/interfaces/SocialNetworks'

export default defineComponent({
  components: {
    Panel,
    Experience
  },
  setup () {
    const person = injectStrict(PersonKey)
    const loading = ref(true)
    const workExperiences = ref<IWorkExperience[]>([])
    const educationalExperiences = ref<IEducationalExperience[]>([])
    onMounted(async () => {
      if (person.value) {
        loading.value = true
        try {
          const workExpPromise = PersonApi.findWorkExperiences(person.value.id)
          const eduExpPromise = PersonApi.findEducationalExperiences(
            person.value.id
          )
          workExperiences.value = await workExpPromise
          educationalExperiences.value = await eduExpPromise
        } catch (error) {
          console.log(error)
        } finally {
          loading.value = false
        }
      }
    })
    return {
      loading,
      workExperiences,
      educationalExperiences,
      socialNetworks: computed(() => {
        if (person.value) {
          const keys = Object.keys(person.value.socialNetworks).filter(
            k => person.value?.socialNetworks[k as keyof SocialNetworks]
          )
          return keys
        } else {
          return []
        }
      }),
      person,
      capitalize,
      window
    }
  }
})
